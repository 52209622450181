.header_container {
  text-align: center;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta {
  margin-top: 2rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
.bottom_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*img*/
.me {
  width: 26rem;
  height: 26rem;
  overflow: hidden;
  margin: 0 auto 2rem;
}

.card {
  width: fit-content;
  position: absolute;
  background: rgb(96 125 139 / 15%);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.123);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  animation: float 3s ease-in-out infinite;
  padding: 0.5rem 1.2rem;
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.11);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.041);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.137);
    transform: translatey(0px);
  }
}
.hello_card {
  text-align: left;
  top: 11rem;
  left: calc(50% - 18rem);
  display: flex;
  gap: 1rem;
  align-items: center;
}
.icon_card {
  font-size: 2rem;
}
.name-text {
  font-size: 28px;
  font-weight: bolder;
  color: #000;
}
.name-fdm {
  color: var(--color-primary);
}
.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}
.header_socials a {
  background: rgb(96 125 139 / 15%);
  padding: 10px 10px 0;
  border-radius: 50%;
}
.header_socials::after {
  content: "";
  width: 1.5px;
  height: 3rem;
  background: var(--color-primary);
}
.about_content {
  color: #000;
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: normal;
  padding: 10px 0;
  max-width: 950px;
}
.scroll_container {
  width: 0;
  transform: rotate(90deg);
  margin-bottom: 5rem;
}
.scroll_down {
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1.2rem;
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .header_socials,
  .scroll_down,
  .web_dvp,
  .Marketing {
    display: none;
  }
  .hello_card {
    position: unset;
    margin: 1rem auto;
  }

  .me {
    width: 100%;
    height: auto;
  }
}
