.skills {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0 1rem;
  justify-content: center;
}
.skill {
  border: 1px solid #000;
  padding: 0px 10px;
  padding-top: 5px;
  border-radius: 5px;
  margin: 5px 10px 5px 0px;
  font-size: 20px;
}
.mailchimp-item {
  margin: 2rem 0;
}
.mailchimp-item img {
  border-radius: 10px;
  width: 350px;
  float: left;
  margin-right: 25px;
}
.mailchimp-text {
  font-size: 19px;
  text-align: left;
}
.portfolio_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 100%;
  margin: 2.5rem 0;
}
.portfolio_item {
  background: transparent;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid transparent;
  transition: var(--transition);
  border-color: var(--color-primary-variant);
}
.portfolio_item-image {
  border-radius: 1rem;
  overflow: hidden;
}

.portfolio_item h3 {
  margin: 1.2rem 0;
  font-size: 22px;
  color: #000;
  transition: var(--transition);
}
.portfolio_item .project-steps {
  padding-left: 25px;
}
.portfolio_item .project-steps li {
  list-style-type: circle;
  text-align: left;
  font-size: 1rem;
  color: #000;
}
@media screen and (max-width: 1024px) {
  .mailchimp-item img {
    width: 230px;
  }
  .portfolio_container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}
@media screen and (max-width: 600px) {
  .portfolio_container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .mailchimp-item img {
    margin-bottom: 10px;
    float: none;
  }
}
